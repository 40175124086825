import Dashboard from '../dashboard/containers';
import pageLayoutHoc from '../../hocs/pageLayoutHoc';
import userRoleBaseAccessHoc from '../../hocs/userRoleBaseAccessHoc';
import dashboardWrapper from './containers'
export const dashboardRoutes = [
  {
    path: '/dashboard',
    component: pageLayoutHoc(userRoleBaseAccessHoc(dashboardWrapper(Dashboard),[]), {apps: 'Dashboard', layoutPhase: 1}),
    key: 'Dashboard'
  }
]
