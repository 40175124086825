import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import config from '../../config';
import homeIcon from '../../assets/images/home_icon.png';
import dbIcon from '../../assets/images/dashbordIcon.svg';
import reportIcon from '../../assets/images/report_icon.png';
import settingIcon from '../../assets/images/setting.svg';
import { getLocalStorageInfo } from '../../utils';
export const MainNavLink = ({match}) =>{
  let securityGroups = getLocalStorageInfo().user.security_groups.map(group => {return group.name});
  return(
    <Fragment>
    <ul className="list-unstyled" >
      <li>
        <a href={`${config.domain.subDomian}`} target="_blank">
          <img src={homeIcon} alt="Home" />
          <p>Home</p>
        </a>
      </li>
      <li>
        <Link className={match.path.includes('dashboard') ? 'active':''} to="/dashboard">
          <img src={dbIcon} alt="Dashboard" />
          <p>Dashboard</p>
        </Link>
      </li>
      <li>
        <Link className={match.path.includes('assets-listing') || match.path.includes('maintenance') ? 'active':''} to="/assets-listing">
          <img src={`${config.api.s3ImageUrl}asset_list_icon.png`} alt="Assets" />
          <p>Assets</p>
        </Link>
      </li>
      { securityGroups.includes('Records Administrator') || securityGroups.includes('Lessor Administrators') ?
          <li>
            <Link className={match.path.includes('settings') ? 'active':''} to='/settings/users'>
              <img src={settingIcon} alt="Settings" />
              <p>Settings</p>
            </Link>
          </li>:null
      }
    </ul>
    </Fragment>
  )
}

export default withRouter(MainNavLink);
