import React, {Fragment, Component} from 'react';
import { TableRow, TableCell, Tooltip } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import EditEngineLLP from './EditEngineLLP';
import { imgStoragePath } from '../../../../constants';
import { DeletePopUp } from '../../../../shared_elements';
import {deleteLLPApi} from '../apiServices';
import { checkApiStatus } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
class LLPItem extends Component {
  constructor(props){
    super(props)
    this.state = {
      deleteModal: false,
      deleteId:''
    }
    this.deleteLLPApi = deleteLLPApi.bind(this)
  }
  deleteLLp=() => {
    console.log('lets start');
    const { deleteId } = this.state
    this.deleteLLPApi(this.props, deleteId)
    .then(response=>{
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar(response.data.message, {variant:"success", anchorOrigin:{vertical:'top', horizontal:'right'}})
        this.setState({deleteModal:false})
        this.props.getResponseBack();
        trackActivity('Item Deleted', {
            page_type: 'LLP',
            page_title: 'Engine LLP',
            asset_slug: this.props.match.params.assetSlug,
            asset_type: this.props.match.params.assetType,
            assembly_type: this.props.assemblyType
          })
      }else {
        this.props.enqueueSnackbar(response.data.message,{variant:'error', anchorOrigin:{vertical:'top', horizontal:'right'}})
      }
    })

  }
  render() {
    const { item, getResponseBack} = this.props
    const { deleteId, deleteModal } = this.state
    return(
      <Fragment>
        <TableRow hover tabIndex={-1}>
          <TableCell>{item.module}</TableCell>
          <TableCell>{item.description}</TableCell>
          <TableCell>{item.part_number}</TableCell>
          <TableCell>{item.cycles_max_limit}</TableCell>
          <TableCell>{item.cycles_remaining}</TableCell>
          <TableCell>{item.list_price}</TableCell>
          <TableCell>
            <EditEngineLLP llp={item} getResponseBack={getResponseBack} />
            <Tooltip title="Delete" arrow>
              <img width="16" onClick={()=> this.setState({deleteModal:true, deleteId:item.id}, () => trackActivity('Item Clicked', { page_title: 'Engine LLP', item_type: 'Delete', assembly_type: this.props.assemblyType }))} src={imgStoragePath+"delete_red.png"} style={{marginLeft:"6px", cursor:'pointer'}} />
            </Tooltip>
          </TableCell>
        </TableRow>
        <DeletePopUp
          modal={deleteModal}
          toggleModalFn={() => this.setState({deleteModal:false, deleteId:{}}) }
          title="Delete LLP"
          deleteRecordFn={()=> this.deleteLLp()}
          content={<h4>Are you sure you want to delete?</h4>}
        />
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(LLPItem));
