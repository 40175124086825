import { globalGetService, globalPostService, globalPutService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
export function getLgUpcomingEventListApi(props={}, query={}){
  globalGetService(`maintenance/asset-type/lg/asset/${props.match.params.assetSlug}/upcoming-events/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        upcomingShopVisit: response.data.data.upcoming_events
      })
    }
  })
}


export function toggleLgShopVisitStatusApi(props={}, data={}){
  globalPostService(`maintenance/shopvisit/${props.match.params.id}/update-status/`, data)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        event: response.data.data
      }))
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
    else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function toggleLgClaimStatusApi(props={}, id, data={}){
  globalPostService(`maintenance/claim/${id}/update-status/`, data)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        claims: prevState.claims.map(claim => claim.id === id ? response.data.data:claim),
      }));
    }
  })
}

export function getMntAfEvents(props={}, query){
  globalGetService(`maintenance/asset-type/lg/asset/${props.match.params.assetSlug}/events/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        mntEvents: response.data.data
      });
    }
  })
}
