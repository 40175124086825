import React, { Component, Fragment } from 'react';
import { Paper, Button, Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { withSnackbar } from 'notistack';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ShopVisitCard, ClaimCard, ClaimPayoutCard, EditClaimPayout, AddEditClaim, AddEditShopVisit, RejectionDialog, ShopVisitTitle } from '../../Elements';
import { toggleLgShopVisitStatusApi } from '../apiServices';
import { getAssemblyShopVisitApi, getAssemblyClaimListApi, getAssemblyEventsApi, addEditAssemblyShopVisitApi, toggleAssemblyClaimStatusApi, addEditAssemblyClaimApi } from '../../apiServices';
import { PageLoader} from '../../../../shared_elements';
import { permissionCheckFn } from '../../../../utils';
class LgAssemblyView extends Component {
  constructor(props){
    super(props);
    this.state = {
      event: {},
      claims: [],
      mntEvents:[],
      claim: {
        claim_amount: '',
        description: '',
        remarks: '',
        submission_date: null,
        shop_visit_id: this.props.match.params.id
      },
      pageLoader: false
    }
    this.getAssemblyEventsApi = getAssemblyEventsApi.bind(this)
    this.getAssemblyClaimListApi = getAssemblyClaimListApi.bind(this);
    this.getAssemblyShopVisitApi = getAssemblyShopVisitApi.bind(this);
    this.toggleAssemblyClaimStatusApi = toggleAssemblyClaimStatusApi.bind(this)
    this.toggleLgShopVisitStatusApi = toggleLgShopVisitStatusApi.bind(this);
    this.addEditAssemblyShopVisitApi = addEditAssemblyShopVisitApi.bind(this)
    this.addEditAssemblyClaimApi = addEditAssemblyClaimApi.bind(this)
  }
  componentDidMount(){
    this.getAssemblyShopVisitApi(this.props, 'lg');
    this.getAssemblyClaimListApi(this.props);
    this.getAssemblyEventsApi(this.props, 'lg')
  }
  render(){
    const { event, claims, claim, mntEvents, pageLoader } = this.state;
    return(
      <div className="maintenance-module-section">
        { pageLoader ? <PageLoader /> :
          <Fragment>
            <ShopVisitTitle
              event={event}
              assemblyType="airframe"
              goBack={()=> this.props.history.push(`/aircraft/${this.props.match.params.assetSlug}/maintenance/landing-gears`)}
            />
            <ShopVisitCard
              item={event}
              mntEvents={mntEvents}
              assemblyType="lg"
              changeSVStatus={(data) => this.toggleLgShopVisitStatusApi(this.props, data)}
              getResponseBack={()=> this.getAssemblyShopVisitApi(this.props)}
              rejectionFn={(data)=> this.addEditAssemblyShopVisitApi(this.props,data,'lg')}
              buttonText="Shop Visit"
              eventId={event.event_id}
              eventName={event.name}
            />
          </Fragment>
        }
      { event.status && event.status.value === 3 ?
          <Fragment>
          { permissionCheckFn({secondaryKey:'landing_gears', keyIndex:'U'}) ?   
            claims.length ?
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <h4 class="mr-claim">MR Claim</h4>
              </Grid>
              <Grid item xs={12} md={6}>
                <AddEditClaim
                  buttonText='Add Claim'
                  shopVisitId={parseInt(this.props.match.params.id)}
                  mode="Add"
                  claimData={claim}
                  heading='Add Claim'
                  getResponseBack={()=> this.getAssemblyClaimListApi(this.props)}
                />
              </Grid>
            </Grid> :
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="no-records-found">
                  <div className="inner-wrapper">
                    <p className="claim-text">No Claim(s) Added. Please Proceed with Adding the Claims.</p>
                    <AddEditClaim
                      buttonText='Add Claim'
                      shopVisitId={parseInt(this.props.match.params.id)}
                      mode="Add"
                      claimData={claim}
                      heading='Add Claim'
                      size="large"
                      variant="outlined"
                      getResponseBack={()=> this.getAssemblyClaimListApi(this.props)}
                    />
                  </div>
                </div>
              </Grid>
            </Grid> : null
            }
            { claims.map((claim ,index) =>
              <Paper elevation={3} className="mnt-event-card" key={index}>
                <ClaimCard
                  item={claim}
                  assemblyType="lg"
                  changeClaimStatus={(data) => this.toggleAssemblyClaimStatusApi(this.props, claim.id, data)}
                  mntEvents={mntEvents}
                  getResponseBack={()=> this.getAssemblyClaimListApi(this.props)}
                  rejectionFn={(data)=> this.addEditAssemblyClaimApi(this.props, data, 'lg')}
                />
                { claim.status.value == 3 ?
                  <ClaimPayoutCard
                    item={claim}
                    assemblyType="Event"
                    changeClaimStatus={(data) => this.toggleAssemblyClaimStatusApi(this.props, claim.id, data)}
                    getResponseBack={()=> this.getAssemblyClaimListApi(this.props)}
                  />:null
                }
              </Paper>
            )}
          </Fragment>
          :null
        }
      </div>
    )
  }
}
export default withSnackbar(LgAssemblyView);
