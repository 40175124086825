import React, {Fragment,Component} from 'react';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import { addEditAssemblyShopVisitApi } from '../apiServices';
import { shopVisitObj, errorCode } from '../';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { fieldValidation } from '../../../utils/formValidation';
import { capitalizeFirstLetter, checkApiStatus, checkLicenseStatus } from '../../../utils';
import { regexConstants} from '../../../constants/regEx';
import { trackActivity } from '../../../utils/mixpanel';
import { LicenseFailModal } from '../../../shared_elements';
class AddEditShopVisit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal:false,
      licenseModal:false,
      licenseModalMessage:'',
      shopVisit:{},
      error:{},
      mode:'',
      heading:''
    }
    this.addEditAssemblyShopVisitApi = addEditAssemblyShopVisitApi.bind(this);
  }

  updateErrorField = (key, message) => {
    this.setState(prevState =>({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:message
      }
    }))
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      shopVisit: {
        ...prevState.shopVisit,
        [keyParam]: data
      }
    }))
  }
  addEditShopVisit = () => {
    const { shopVisit } = this.state
    const { assemblyType } = this.props;
    let validationInputs = {
      event:errorCode['event'][fieldValidation({...errorCode['eventObj'], fieldval: shopVisit.event})],
      shop_visit_date:errorCode['shop_visit_date'][fieldValidation({...errorCode['shop_visit_dateObj'], fieldval:shopVisit.shop_visit_date})],
      cost:errorCode['cost'][fieldValidation({...errorCode['costObj'], fieldval:shopVisit.cost})],
      tsn:errorCode['tsn'][fieldValidation({...errorCode['tsnObj'], fieldval:shopVisit.tsn})],
      csn:errorCode['csn'][fieldValidation({...errorCode['csnObj'], fieldval:shopVisit.csn})]
    }
    if(Object.keys(validationInputs).every((k)=>{return validationInputs[k] ==='' })){
      this.addEditAssemblyShopVisitApi(this.props, shopVisit, assemblyType)
      .then(response => {
        if(checkApiStatus(response)){
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
          this.setState({modal:false, mode:'', shopVisit:{}, error:{}});
          this.props.getResponseBack();
          trackActivity('Item Added / Edited', {
              page_type: this.props.pageType,
              page_title: 'Shop visit',
              asset_slug: this.props.match.params.assetSlug,
              asset_type: this.props.match.params.assetType,
              assembly_type: this.props.assemblyType,
            })
        }else if(checkLicenseStatus(response)){
          this.setState({modal:false, mode:'', shopVisit:{}, error:{},});
          this.props.toggleLicenseFn(true, response.data.message)
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }else {
      this.setState({error: validationInputs})
    }
  }
  render(){
    const { modal,shopVisit, error } = this.state
    const { id, buttonText, assemblyType, mntEvents, shopvisitData, mode, eventName, eventId} = this.props;
    return (
      <Fragment>
        { id ? <EditIcon onClick={() => this.setState({modal:true, mode:'edit',shopVisit:{...shopvisitData,event:{ event_id:eventId ,event_name: eventName }}, error:{}})} color="primary" /> :
           <Button className="add-sv-cta" color="primary" variant="contained" onClick={() => this.setState({modal:true, shopVisit:shopVisitObj, error:{}}, () => trackActivity('Item Clicked', { item_type: `${mode} ${buttonText}`, assembly_type: this.props.assemblyType }))}>+ {mode} {buttonText}</Button>
         }
        { modal ?
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false, mode:'add', shopVisit:shopVisitObj, error:{}})}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              {capitalizeFirstLetter(mode)} {buttonText}
            </DialogTitle>
            <DialogContent dividers={true}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {shopVisit.id ? shopVisit.name:
                    <Autocomplete
                      options = {mntEvents}
                      getOptionLabel={option => option.event_name }
                      id="event"
                      value={shopVisit.event}
                      onChange={(e, value) => this.onFieldChange(e, 'event', value)}
                      renderInput={params =>
                        <TextField  required {...params} label={assemblyType == 'airframe' ? 'Select Maintenance Event' : 'Select Shop Visit for' } margin="normal" fullWidth InputLabelProps={{shrink: true}} error={error.event ?true:false } helperText={error.event ? assemblyType == 'Maintenance Event' ? 'Please select Maintenance Event': error.event :''} onFocus={()=>this.updateErrorField('event','')}  />}

                    />
                  }
                </Grid>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                       required
                       margin="normal"
                       id="start_date"
                       label={assemblyType === "airframe" ? 'Event Date' : 'Shop Visit Date'}
                       format={fieldDateFormat}
                       fullWidth
                       InputLabelProps={{shrink: true}}
                       value={shopVisit.shop_visit_date ? moment(shopVisit.shop_visit_date).format('MMM DD, YYYY'):null}
                       onChange={(data, value) => this.onFieldChange(value, 'shop_visit_date', data ? moment(data).format(backendDateFormat):data)}
                       onFocus={()=>this.updateErrorField('shop_visit_date', '')}
                       maxDate={moment()}
                       error={ error.shop_visit_date ? true:false }
                       helperText={error.shop_visit_date ? assemblyType === 'airframe' ? 'Please enter Event Date': error.shop_visit_date : ''}
                     />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="cost"
                    label={
                      <Box style={isMobile ? { position:'relative', top:'-18px'} : {}}>
                        { assemblyType === 'airframe' ? <p>Event Cost (USD)<sup>*</sup></p> : <p>Shop Visit Cost (USD)<sup>*</sup></p>}
                      </Box>
                    }
                    fullWidth
                    margin="normal"
                    value={shopVisit.cost}
                    onChange={(e, value) => {regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e,'cost', e.target.value):e.preventDefault()}}
                    error={error.cost ? true:false }
                    onFocus={()=>this.updateErrorField('cost', '')}
                    InputLabelProps={{shrink: true}}
                    helperText={error.cost ? assemblyType === 'airframe' ?'Please enter Event Cost': error.cost :''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" checked={shopVisit.prsv} onChange={(e) => this.onFieldChange(e, 'prsv', e.target.checked)} value=" PRSV" />
                    }
                    label="PRSV Event"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="removal_reason"
                    label={assemblyType === "airframe" ? 'Reason for Event': 'Reason for Shop Visit'}
                    fullWidth
                    multiline
                    rows="4"
                    margin="normal"
                    value={shopVisit.removal_reason}
                    onChange={(e, value) => this.onFieldChange(e, 'removal_reason', e.target.value)}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="workscope_details"
                    label={assemblyType === 'airframe' ? "Event Workscope / Details":"Shop Visit Workscope / Details"}
                    fullWidth
                    margin="normal"
                    multiline
                    rows="4"
                    value={shopVisit.workscope_details}
                    onChange={(e, value) => this.onFieldChange(e, 'workscope_details', e.target.value)}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="shop_visit_facility"
                    label={assemblyType === 'airframe' ? "Event Facility" : "Shop Visit Facility"}
                    fullWidth
                    margin="normal"
                    value={shopVisit.shop_visit_facility}
                    onChange={(e, value) => this.onFieldChange(e, 'shop_visit_facility', e.target.value)}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="facility_location"
                    label="Facility Location"
                    fullWidth
                    margin="normal"
                    value={shopVisit.facility_location}
                    onChange={(e, value) => this.onFieldChange(e, 'facility_location', e.target.value)}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    id="tsn"
                    label={assemblyType === "airframe" ? 'TSN at Event': 'TSN at Shop Visit'}
                    fullWidth
                    margin="normal"
                    value={shopVisit.tsn}
                    onFocus={()=> this.updateErrorField('tsn', '')}
                    onChange={(e, value) => {regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'tsn', e.target.value):e.preventDefault()}}
                    error={error.tsn ? true:false }
                    InputLabelProps={{shrink: true}}
                    helperText={error.tsn ? assemblyType === 'airframe' ? 'Please enter TSN at Event': error.tsn :''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    id="csn"
                    label={assemblyType === 'airframe' ?"CSN at Event":"CSN at Shop Visit"}
                    fullWidth
                    margin="normal"
                    value={shopVisit.csn}
                    onFocus={()=>this.updateErrorField('csn', '')}
                    onChange={(e, value) => {regexConstants.onlyNumeric.test(e.target.value)? this.onFieldChange(e, 'csn', e.target.value):e.preventDefault()}}
                    error={error.csn ? true:false }
                    InputLabelProps={{shrink: true}}
                    helperText={error.csn ? assemblyType === 'airframe' ? 'Please enter CSN at Event': error.csn : '' }
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({modal:false, mode:'add', shopVisit:shopVisitObj, error:{}}, () => trackActivity('Item Clicked', { page_title: 'Shop Visit', page_type: this.props.pageType, activity_type: 'Cancel', assembly_type: this.props.assemblyType }))} color="primary">Cancel</Button>
              <Button variant="contained" onClick={this.addEditShopVisit} color="primary">Save</Button>
            </DialogActions>
          </Dialog>:null
        }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(AddEditShopVisit));
