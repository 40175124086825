import AssetListing from '../home/containers';
import pageLayoutHoc from '../../hocs/pageLayoutHoc';
import userRoleBaseAccessHoc from '../../hocs/userRoleBaseAccessHoc';
export const assetRoutes = [
  {
    path: '/assets-listing',
    component: pageLayoutHoc(userRoleBaseAccessHoc(AssetListing,[]), {apps: 'Assets', layoutPhase: 1}),
    key: 'MntAssetList'
  }
]
