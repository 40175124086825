export const PLATFORM_CONTANTS = 'platform/constants/list';
export const AIRCRAFT_TYPES = 'platform/aircraft_types';
export const PORTFOLIO_LIST = 'platform/portfolios';
export const ALL_ASSETS = 'platform/all/assets';
export const ENGINE_TYPES = 'platform/engine_types';
export const LESSOR_LIST = 'platform/lessor';
export const REGION_LIST = 'platform/regions';
export const LESSEE_LIST = 'platform/lessee';
export const OPERATOR_LIST = 'platform/operator';
export const MANUFACTURER_LIST = 'platform/manufacturer';
export const APU_TYPES = 'platform/apu_types';
export const APPS_SWITCHER = 'platform/app/switcher';
export const AIRCRAFT_MNT_GRP = 'platform/aircraft/mnt/grp';
