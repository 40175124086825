import React, { Fragment, Component } from 'react';
import { isMobile } from 'react-device-detect';
import { Redirect } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DescriptionIcon from '@material-ui/icons/Description';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, } from '@material-ui/core'
import { getLocalStorageInfo, getGlobalCookie, authorizeAutoLogin, getLessorsList, setGlobalCookie } from '../utils';
import { imgPath } from '../constants'
import { lessorUpdateApi } from '../application/auth/apiServices';
import { DesktopHeader, MobileHeader, DesktopSideNav, MntSideNav, MobileSecMenu, AccessDenied } from '../shared_elements';
import { connect } from 'react-redux';
import config from '../config';
import * as actions from '../shared_elements/actions'
import { getAllAssetListAc } from '../shared_elements/actionCreators'
import mrCalculatorApp from '../assets/images/acumen_commverge.png';
import restrictIcon from '../assets/images/restrict_icon.svg';
import contractsApp from '../assets/images/LeaseIcon.svg';
import projectsApp from '../assets/images/ProjectsIcon.svg';
import recordsApp from '../assets/images/MaintenaceIcon.svg';
import maintenanceApp from '../assets/images/MaintenaceIcon.svg';
import valuationApp from '../assets/images/AcumenFin-SLogo.png';
import { color } from 'highcharts';
window.addEventListener('visibilitychange', checkLogin)
function checkLogin(){
  if(document.visibilityState || document.hasFocus){
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let location = window.location.href
    let lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'))
    if(!window.location.href.includes('login') && !window.location.href.includes('select-lessor')){
        setGlobalCookie('redirectURIMaintenance', window.location.href, 3)
    }
    setTimeout(() => {
      if (!lessorAccess) {
        localStorage.clear();
        if (
          !location.includes("login") &&
          !location.includes("shared-data") &&
          !location.includes("records/preview")
        ) {
          window.location.reload();
        }
      } else {
        if (
          lessorAccess.id &&
          config.api.networkInterface.includes(lessorAccess.environment)
        ) {
          if (!getLocalStorageInfo()) {
            authorizeAutoLogin({ lessor_id: lessorAccess.id });
          } else if (
            !location.includes("select-lessor") &&
            !location.includes("otp")
          ) {
            if (lessorAccess.id !== userInfo.defaultLessor.id) {
              authorizeAutoLogin({ lessor_id: lessorAccess.id });
            } else {
              //window.location.assign('/')
            }
          }
        } else {
          if (userInfo && userInfo.defaultLessor == undefined) {
            // window.location.assign("/select-lessor");
          }
        }
      }
    });
    // getLessorsList()
  }
}
export default function PageLayout(HocComponent, extraProps = {}) {
  class Layout extends Component {
    state = {
      filterAssets: [
        {
          id: 1,
        },
      ],
    };
    layoutMarginProps = () => {
      if (extraProps.layoutPhase) {
        switch (extraProps.layoutPhase) {
          case 0:
            // Desktop with Primary Header Only
            return "50px 0 0 0";
          case 1:
            // Desktop with Primary Header + Primary Sidenav
            return "0px 0 0 70px";
          case 2:
            // Desktop with Primary Header + Primary Sidenav + Secondary Sidenav
            return "0 0 0 250px";
          default:
            return "0";
        }
      } else {
        return "0";
      }
    };
    render() {
      const fileIcons = this.props.pltConstants.filter(
        (icon) => icon.type === "file_extension_drive"
      );
      if (getLocalStorageInfo().user.permission.maintenance) {
        return (
          <section>
            <Fragment>
              {isMobile ? (
                <MobileHeader />
              ) : (
                <Fragment>
                  <DesktopHeader />
                  <DesktopSideNav />
                </Fragment>
              )}
            </Fragment>
            {isMobile ? (
              <MobileSecMenu extraProps={extraProps} />
            ) : extraProps.layoutPhase == 2 ? (
              <div>
                <div className="secondary-sidenav">
                  <div style={{ padding: "47px 0" }}>
                    <MntSideNav />
                  </div>
                </div>
              </div>
            ) : null}
            <main
              style={{
                margin: isMobile ? "10px 0 0 0" : this.layoutMarginProps(),
                padding: isMobile
                  ? "15px 15px 50px 15px"
                  : "20px 20px 58px 20px",background:"white",
              }}
            >
              <div>
                <HocComponent {...this.props} />
              </div>
            </main>
            <Dialog
              open={this.props.applicationSwitcher}
              onClose={() => this.props.toggleTheAppSwitcher(false)}
              aria-labelledby="scroll-dialog-title"
              fullScreen={true}
            >
              <DialogTitle id="scroll-dialog-title">
                Applications
                <span
                  style={{ float: "right", cursor: "pointer" }}
                  onClick={() => this.props.toggleTheAppSwitcher(false)}
                >
                  <CloseIcon color="primary" />
                </span>
              </DialogTitle>
              <DialogContent dividers={true} className="data-upload-modal">
                <Grid container spacing={2}>
                  {[
                      {name:'Lease Management', url:config.domain.lease, access:getLocalStorageInfo().user.permission.contracts ? true:false, icon:contractsApp, description:'Effectively manage commerical leases and contracts of the entire fleet - aircraft, engines and even sub-modules through the entire economic life of the asset'},
                      {name:'Projects Management', url:config.domain.projects, access:getLocalStorageInfo().user.permission.technical ? true:false, icon:projectsApp, description:'Comprehensive solution for planning, executing and managing Technical Projects - from Pre-Purchase to Redeliveries.'},
                    {
                      name:'Records Dataroom',url:config.domain.records,access:getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.records ? true:false,icon:recordsApp,
                      description:'Centralised, secure online Documents Management System (DMS) for digitised aircraft records storage retrieval and collaboration'
                    },
                    {name:'CommVerge', url:config.domain.mrCalculator, access:getLocalStorageInfo().user.permission.mr_calculator ? true:false, icon:mrCalculatorApp, description:'A potent decision application for computing commercial exposure and simulating complex scenarios on ~200 aircraft - engine combinations.'},
                    {name:'fin-S', beta:true, url:config.domain.valuation, access:getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.valuation, icon:valuationApp, description:'Access values for a wide variety of aircraft, powered by expertise and experience of ISTAT and ASA Certified Appraisers.'}
                  ].map((app) => (
                    <Grid item xs={12} md={4}>
                      <div
                        className="application-card"
                        style={{ opacity: app.access ? 0.9 : 0.7 }}
                      >
                        <div className="application-info">
                          <h3 className="flex-centered">
                            <img width={40} src={app.icon} alt="" />
                            <span>{app.name}</span>
                            {app.beta ? (
                              <sup style={{ color: "rgb(241, 9, 9)" }}>
                                {" "}
                                BETA
                              </sup>
                            ) : null}
                          </h3>
                          <p>{app.description}</p>
                        </div>
                        { app.access ?
                            <Button onClick={() => window.open(app.url, '_blank')} size="small" endIcon={<ArrowForwardIosIcon fontSize="small" />}>Enter</Button>:
                            <p className="restrict-access">Please contact support@sparta.aero to access this application</p>
                          }
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </DialogContent>
            </Dialog>
          </section>
        );
      } else {
        return (
          <Fragment>
            {isMobile ? (
              <MobileHeader />
            ) : (
              <Fragment>
                <DesktopHeader />
                <DesktopSideNav />
              </Fragment>
            )}
            <AccessDenied/>
          </Fragment>
        );
      }
    }
  }
  const mapStateToProps = (state) => ({
    pltConstants: state.shareReducer.pltConstants,
    applicationSwitcher: state.shareReducer.applicationSwitcher,
    allAssets: state.shareReducer.allAssets,
  });
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      getAllAssetListFn: () => dispatch(getAllAssetListAc()),
      toggleTheAppSwitcher: (flag) =>
        dispatch({ type: actions.APPS_SWITCHER, payload: flag }),
    };
  };
  return connect(mapStateToProps, mapDispatchToProps)(Layout);
}
