import React, { Component,Fragment } from 'react';
import { Paper, Grid } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { ShopVisitCard, ClaimCard, ClaimPayoutCard, AddEditClaim, ShopVisitTitle } from '../../Elements';
import { toggleAfShopVisitStatusApi } from '../apiServices';
import { getAssemblyShopVisitApi, getAssemblyClaimListApi, toggleAssemblyClaimStatusApi, getAssemblyEventsApi, addEditAssemblyShopVisitApi, addEditAssemblyClaimApi } from '../../apiServices';
import { PageLoader } from '../../../../shared_elements';
import { permissionCheckFn } from '../../../../utils';
class AirframeAssemblyView extends Component {
  constructor(props){
    super(props);
    this.state = {
      event: {},
      claims: [],
      mntEvents:[],
      claim:{
        claim_amount: '',
        description: '',
        remarks: '',
        submission_date: null,
        shop_visit_id: this.props.match.params.id
      },
      pageLoader:false
    }
    this.getAssemblyEventsApi = getAssemblyEventsApi.bind(this);
    this.getAssemblyClaimListApi = getAssemblyClaimListApi.bind(this);
    this.getAssemblyShopVisitApi = getAssemblyShopVisitApi.bind(this);
    this.toggleAssemblyClaimStatusApi = toggleAssemblyClaimStatusApi.bind(this)
    this.toggleAfShopVisitStatusApi = toggleAfShopVisitStatusApi.bind(this)
    this.addEditAssemblyShopVisitApi = addEditAssemblyShopVisitApi.bind(this)
    this.addEditAssemblyClaimApi = addEditAssemblyClaimApi.bind(this)
  }

  componentDidMount(){
    this.getAssemblyClaimListApi(this.props, 'airframe')
    this.getAssemblyEventsApi(this.props, 'airframe');
    this.getAssemblyShopVisitApi(this.props, 'airframe')
  }
  render(){
    const { event,claims, claim, mntEvents, pageLoader } = this.state;
    return(
      <div className="maintenance-module-section">
        { pageLoader ? <PageLoader /> :
          <Fragment>
            <ShopVisitTitle
              event={event}
              assemblyType="airframe"
              goBack={()=> this.props.history.push(`/aircraft/${this.props.match.params.assetSlug}/maintenance/airframe`)}
            />
          {Object.keys(event).length ?
            <ShopVisitCard
              item={event}
              mntEvents={mntEvents}
              assemblyType="airframe"
              changeSVStatus={(data) => this.toggleAfShopVisitStatusApi(this.props, data)}
              getResponseBack={() => this.getAssemblyShopVisitApi(this.props, 'airframe')}
              rejectionFn={(data) =>  this.addEditAssemblyShopVisitApi(this.props, data,  'airframe')}
              buttonText="Maintenance Event"
              eventId={event.event_id.id}
              eventName={event.event_id.name}
            />: null
          }
          </Fragment>
        }
        { event.status && event.status.value === 3 ?
          <Fragment>
          { permissionCheckFn({secondaryKey:'airframe', keyIndex:'U'}) ? 
            claims.length ?
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <h4 class="mr-claim">MR Claim</h4>
              </Grid>
              <Grid item xs={12} md={6}>
                <AddEditClaim
                  buttonText='Add Claim'
                  shopVisitId={parseInt(this.props.match.params.id)}
                  mode="Add"
                  claimData={claim}
                  heading='Add Claim'
                  getResponseBack={()=> this.getAssemblyClaimListApi(this.props)}
                />
              </Grid>
            </Grid> :
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="no-records-found">
                  <div className="inner-wrapper">
                    <p className="claim-text">No Claim(s) Added. Please Proceed with Adding the Claims.</p>
                    <AddEditClaim
                      buttonText='Add Claim'
                      shopVisitId={parseInt(this.props.match.params.id)}
                      mode="Add"
                      claimData={claim}
                      heading='Add Claim'
                      size="large"
                      variant="outlined"
                      getResponseBack={()=> this.getAssemblyClaimListApi(this.props)}
                    />
                  </div>
                </div>
              </Grid>
            </Grid> : null
            }
            { claims.map((claim, index) =>
                <Paper key={index} elevation={3} className="mnt-event-card">
                  <ClaimCard
                    item={claim}
                    assemblyType="airframe"
                    assembly="airframe"
                    changeClaimStatus={(data) => this.toggleAssemblyClaimStatusApi(this.props, claim.id, data)}
                    mntEvents={mntEvents}
                    getResponseBack={() => this.getAssemblyClaimListApi(this.props)}
                    rejectionFn={(data)=> this.addEditAssemblyClaimApi(this.props, data, 'airframe')}
                  />
                  { claim.status.value === 3 ?
                    <ClaimPayoutCard
                      item={claim}
                      assemblyType="Event"
                      changeClaimStatus={(data) => this.toggleAssemblyClaimStatusApi(this.props, claim.id, data)}
                      getResponseBack={() => this.getAssemblyClaimListApi(this.props)}
                    />:null
                  }
                </Paper>
              )}
            </Fragment>:null
          }
      </div>
    )
  }
}
export default withSnackbar(AirframeAssemblyView);
