import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import {getCurrencyFormat} from '../../../utils';
import moment from 'moment';
export default function UpcomingShopVisit({item, assembly}){
  return(
    <div className="upcoming-shop-visit">
      <span>{item.eventDate ? moment(item.eventDate).format('MMM DD, YYYY'):''}</span>
      <Grid container spacing={0} className="shopvisit-row">
        <Grid item xs={12} md={2}>
          <h4>{item.name}</h4>
          { item.exceeding_contract ?
            <label>Upcoming {assembly} exceeds active contract end date</label>:null
          }
        </Grid>
        <Grid item xs={12} md={3}>
          <p>Escalated {assembly} cost as of {assembly} Date</p>
          <h6>{item.eventCost ?<NumericLabel params={getCurrencyFormat()} >{item.eventCost}</NumericLabel>:<NumericLabel params={getCurrencyFormat()} >0</NumericLabel>}</h6>
        </Grid>
        <Grid item xs={12} md={3}>
          <p>Escalated MR Rate as of {assembly} Date</p>
          <h6>{item.mr ? <NumericLabel params={getCurrencyFormat()}>{item.mr}</NumericLabel>:<NumericLabel params={getCurrencyFormat()} >0</NumericLabel>}</h6>
        </Grid>
        { item.fundCollected ?
          <Grid item xs={12} md={2}>
            <p>Fund Status at the time of {assembly}</p>
            <h6>{item.fundCollected ? <NumericLabel params={getCurrencyFormat()}>{item.fundCollected}</NumericLabel> : ''}</h6>
          </Grid>:null
        }
        { item.surPlus ?
          <Grid item xs={12} md={2}>
            <p>Fund Balance</p>
            <h6>{item.surPlus ?  <NumericLabel params={getCurrencyFormat()}>{item.surPlus}</NumericLabel>:''}</h6>
          </Grid>:null
        }
      </Grid>
    </div>
  )
}
