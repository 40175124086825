import { globalGetService, globalPostService, globalPutService, globalDeleteService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
export function getEngineUpcomingEventListApi(props={}, query={}){
  globalGetService(`maintenance/asset-type/engine/asset/${props.match.params.assetSlug}/upcoming-events/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        upcomingShopVisit: response.data.data.upcoming_events
      })
    }
  })
}
export function getEngineClaimListApi(props={}, query={}){
  globalGetService(`maintenance/${props.match.params.assetType}/asset/${props.match.params.assetSlug}/claims/`, {shop_visit_id: props.match.params.id})
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        claims: response.data.data
      })
    }
  })
}

export function getEngineLLPsApi(props={}){
  globalGetService(`maintenance/shopvisit/${props.match.params.id}/llps/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        llps: response.data.data
      })
    }
  })
}
export function addEditEngineClaimApi(props={}, data={}){
  if(data.id){
    globalPutService(`maintenance/${props.match.params.assetType}/asset/${props.match.params.assetSlug}/claims/${data.id}/`, data)
    .then(response => {
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          claims: prevState.claims.map(claim => claim.id === data.id ? response.data.data : claim),
          modalClaim: false
        }))
      }
    })
  }else{
    globalPostService(`maintenance/${props.match.params.assetType}/asset/${props.match.params.assetSlug}/claims/`, data)
    .then(response => {
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          claims: [response.data.data, ...prevState.claims],
          modalClaim: false
        }))
      }
    })
  }
}

export function toggleEngineShopVisitStatusApi(props={}, data={}){
  globalPostService(`maintenance/shopvisit/${props.match.params.id}/update-status/`, data)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        event: response.data.data
      }))
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
    else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function toggleEngineClaimStatusApi(props={}, id, data={}){
  globalPostService(`maintenance/claim/${id}/update-status/`, data)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        claims: prevState.claims.map(claim => claim.id === id ? response.data.data:claim),
      }));
    }
  })
}

export function getMntAfEvents(props={}, query){
  globalGetService(`maintenance/asset-type/engine/asset/${props.match.params.assetSlug}/events/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        mntEvents: response.data.data
      });
    }
  })
}
export function getEngineLLPInfoApi(props={}, assetId=''){
  this.setState({skeletonLoader:true})
  globalGetService(`console/engine/${assetId}/engine-llp/?shop_visit_id=${props.match.params.id}`)
  .then(response => {
    this.setState({skeletonLoader:false})
    if(checkApiStatus(response)){
      this.setState({
        llpInfo:response.data.data
      })
    }
  })
}
export function addEditLLPApi(props={}, data={}){
  return new Promise(
    function(resolve, reject){
      if(data.id){
        globalPutService(`maintenance/shopvisit/${props.match.params.id}/llps/${data.id}/`,data)
        .then(response => {
          resolve(response);
        })
      }else {
        globalPostService(`maintenance/shopvisit/${props.match.params.id}/llps/`, data)
        .then(response => {
          resolve(response);
        })
      }
    }
  )
}
export function deleteLLPApi(props={}, llpId=''){
  return new Promise(
    function(resolve, reject){
      globalDeleteService(`maintenance/shopvisit/${props.match.params.id}/llps/${llpId}/`, {})
      .then(response => {
        resolve(response)
      })
    }
  )
}
