import React, { Component } from 'react';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

class AssetCard extends Component {
    constructor(props) {
        super(props);
    }
    render() {
      const {item} = this.props;
        return(
            <div className="flex-centered">
              {
                item.asset_type == 1 ?
                <img width="32" src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/icon_Aircraft.png" alt="Aircraft" style={{marginRight:'8px'}} /> :
                <img width="32" src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/icon_engine.png" alt="Engine" style={{marginRight:'8px'}} />
              }
              <div>
                <span>{item.asset_type == 1 ? item.aircraft_type ? item.aircraft_type.name : '--' : item.engine_type ? item.engine_type.name : '--'}</span>
                <h4>{item.asset_type == 1 ? `MSN ${item.msn}` : `ESN ${item.esn}`}</h4>
                {
                  item.ownership_type !== undefined && item.ownership_type.value == 2 && item.owner_portfolio.name && item.owner_portfolio.name != ''  ?
                  <ul className="list-inline">
                    <li className="list-inline-item portfolio-type">Managed</li>
                    <li className="list-inline-item">
                    <Tooltip title={`Portfolio: ${item.owner_portfolio.name}`} arrow>
                      <InfoIcon fontSize="small" />
                    </Tooltip>
                    </li>
                  </ul> : null
                }
              </div>
            </div>
        )
    }
}
export default AssetCard;
