import { globalGetService, globalPostService, globalExportService } from '../../utils/globalApiServices';
import { checkApiStatus, getLocalStorageInfo, downloadFileType } from '../../utils';
import { trackActivity } from '../../utils/mixpanel';
export function getAssetListApi(queryParams={}, loaderType){
  this.setState({[loaderType]:true});
  globalGetService(`console/list/?application=2`, {...queryParams, per_page: queryParams.per_page ? queryParams.per_page : 20, exclude_engine_modules: true})
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      delete queryParams.page;
      delete queryParams.per_page;
      delete queryParams.asset_type;
      delete queryParams.sort;
      delete queryParams.sort_by;
      this.setState(prevState => ({
        ...prevState,
        filter:queryParams,
        assets:{
          ...prevState.assets,
          asset_count:response.data.data.asset_count,
          list:response.data.data.asset,
          pagination:{
            ...prevState.assets.pagination,
            total:response.data.data.pagination.total,
            per_page:response.data.data.pagination.per_page,
            page:parseInt(response.data.data.pagination.current_page)
          }
        }
      }))
    }
  })
}
export function addNewAssetApi(props, url, data){
  return new Promise(function(resolve,reject){
    globalPostService(url, data)
    .then(response => {
      resolve(response);
    })
  })
}
export function exportAssetListApi(queryParams={}, loaderType){
  this.setState({pageLoader: true});
  globalExportService('console/list/?application=2', {...queryParams, exclude_engine_modules: true})
  .then(response => {
    this.setState({pageLoader: false});
    downloadFileType(response.data, (`${getLocalStorageInfo().defaultLessor.name}_Maintenance_Assets`), queryParams.download);
    this.props.enqueueSnackbar("Download of Asset List successful", {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    trackActivity('Item Exported', {
        page_title: 'Asset Listing',
      })
  })
}
