import React, { Component, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import {UpcomingEvents,ClaimsList,PageNotFound} from "../components";
import ExportIcon from "../../../assets/images/export_icon.svg";
import Header from "../components/Header";
import {claimsListHd} from "../";
import { trackActivity } from '../../../utils/mixpanel';
import { getAssetWidgetsApi ,getClaimListApi,getUpcomingEventsApi} from '../apiServices';
import upcomingEvents from '../upcomingEventsDemoData'

export default function DashboardWrapper(HocComponent, extraProps = {}) {
  return class extends Component{
    constructor(props){
      super(props);
      this.state = {
        assetFlag : false,
        claimsFlag : false,
        upcomingEventsFlag : false,
        assetData : [],
        claimsData : [],
        upcomingEventsData : []
      }
      this.getAssetWidgetsApi = getAssetWidgetsApi.bind(this);
      this.getClaimListApi = getClaimListApi.bind(this);
      this.getUpcomingEventsApi = getUpcomingEventsApi.bind(this);
    }
    componentDidMount(){
      this.getAssetWidgetsApi();
      this.getClaimListApi();
      this.setState({upcomingEventsData:upcomingEvents.data.upcomingEvents});
      this.setState({upcomingEventsFlag:true})
      this.getUpcomingEventsApi();
      trackActivity('Page Visited', {
        page_title: 'Dashboard'
      })
    }
    render(){
      const  {flag, claimsFlag,claimsData,assetData,assetFlag,upcomingEventsData,upcomingEventsFlag} = this.state;
      let assetWidgetCard
      if(JSON.parse(localStorage.getItem('userInfo')).defaultLessor.id === 348){
        assetWidgetCard = [
          {
            title: "TOTAL ASSETS",
            managed: assetData.filter(asset => [1,2].includes(asset.asset_type) && (asset.ownership_type && asset.ownership_type == 2)).length,
            owned: assetData.filter(asset => asset.status != 4 && [1,2].includes(asset.asset_type) && (asset.ownership_type && asset.ownership_type == 1)).length,
            value: assetData.length - assetData.filter(asset => [1,2].includes(asset.asset_type)).length,
            url: '/assets-listing',
            ownedUrl: "/asset-listing?ownership_type=1",
            managedUrl: "/asset-listing?ownership_type=2",
            classes: "widget-cards marginRight flex-centered total-count"
          },
          {
            title: "OPERATIONAL",
            managed: assetData.filter(asset => asset.status == 1 && [1,2].includes(asset.asset_type) && (asset.ownership_type && asset.ownership_type == 2)).length,
            owned: assetData.filter(asset => asset.status == 1 && [1,2].includes(asset.asset_type) && (asset.ownership_type&& asset.ownership_type == 1)).length,
            value: assetData.filter(asset => asset.status == 1 && [1,2].includes(asset.asset_type)),
            url: "/asset-listing?status=1",
            ownedUrl: "/asset-listing?status=1&ownership_type=1",
            managedUrl: "/asset-listing?status=2&ownership_type=2",
            classes: "widget-cards marginRight marginLeft flex-centered on-lease"
          },
          {
            title: "AOG",
            ownedUrl: "/asset-listing?status=3&ownership_type=1",
            managedUrl: "/asset-listing?status=3&ownership_type=2",
            managed: assetData.filter(asset => asset.status == 3 && [1,2].includes(asset.asset_type) && (asset.ownership_type && asset.ownership_type==2)).length,
            owned: assetData.filter(asset => asset.status == 3 && [1,2].includes(asset.asset_type) && (asset.ownership_type && asset.ownership_type==1)).length,
            value: assetData.filter(asset => asset.status ==3 && [1,2].includes(asset.asset_type)),
            url: "asset-listing?status=3",
            classes: "widget-cards marginRight marginLeft flex-centered on-ground"
          }
        ]
      }
      else{
        assetWidgetCard = [
          {
            title: 'TOTAL ASSETS',
            managed: assetData.filter(asset => [1,2].includes(asset.asset_type) && (asset.ownership_type && asset.ownership_type == 2)).length,
            owned: assetData.filter(asset => [1,2].includes(asset.asset_type) && (asset.ownership_type && asset.ownership_type == 1)).length,
            value: assetData.filter(asset => [1,2].includes(asset.asset_type)).length,
            url: '/assets-listing' ,
            ownedUrl: '/assets-listing?ownership_type=1',
            managedUrl: '/assets-listing?ownership_type=2',
            classes: 'widget-cards total-assets'
          },
          {
            title: 'ON LEASE',
            managed: assetData.filter(asset => asset.status == 1 && [1,2].includes(asset.asset_type) && (asset.ownership_type && asset.ownership_type == 2)).length,
            owned: assetData.filter(asset => asset.status == 1 && [1,2].includes(asset.asset_type) && (asset.ownership_type && asset.ownership_type == 1)).length,
            value: assetData.filter(asset => asset.status == 1 && [1,2].includes(asset.asset_type)).length, url: '/assets-listing?status=1',
            ownedUrl: '/assets-listing?status=1&ownership_type=1',
            managedUrl: '/assets-listing?status=1&ownership_type=2',
            classes: 'widget-cards on-lease'
          },
          {
            title: 'OFF LEASE',
            ownedUrl: '/assets-listing?status=2&ownership_type=1',
            managedUrl: '/assets-listing?status=2&ownership_type=2',
            managed: assetData.filter(asset => asset.status == 2 && [1,2].includes(asset.asset_type) && (asset.ownership_type && asset.ownership_type == 2)).length,
            owned: assetData.filter(asset => asset.status == 2 && [1,2].includes(asset.asset_type) && (asset.ownership_type && asset.ownership_type == 1)).length,
            value: assetData.filter(asset => asset.status == 2 && [1,2].includes(asset.asset_type)).length,
            url: '/assets-listing?status=2',
            classes: 'widget-cards off-lease'
          },
          {
            title: 'ON GROUND',
            ownedUrl: '/assets-listing?status=3&ownership_type=1',
            managedUrl: '/assets-listing?status=3&ownership_type=2',
            managed: assetData.filter(asset => asset.status == 3 && [1,2].includes(asset.asset_type) && (asset.ownership_type && asset.ownership_type == 2)).length,
            owned: assetData.filter(asset => asset.status == 3 && [1,2].includes(asset.asset_type) && (asset.ownership_type && asset.ownership_type == 1)).length,
            value: assetData.filter(asset => asset.status == 3 && [1,2].includes(asset.asset_type)).length,
            url: '/assets-listing?status=3',
            classes: 'widget-cards on-ground'
          },
          {
            title: 'WRITTEN OFF / SOLD',
            ownedUrl: '/assets-listing?status=4&ownership_type=1',
            managedUrl: '/assets-listing?status=4&ownership_type=2',
            managed: assetData.filter(asset => asset.status == 4 && [1,2].includes(asset.asset_type) && (asset.ownership_type && asset.ownership_type == 2)).length,
            owned: assetData.filter(asset => asset.status == 4 && [1,2].includes(asset.asset_type) && (asset.ownership_type && asset.ownership_type == 1)).length,
            value: assetData.filter(asset => asset.status == 4 && [1,2].includes(asset.asset_type)).length, url: '/assets-listing?status=4',
            classes: 'widget-cards written-off'
          }
        ]
      }
      return(
        <>
          <Header
            assetWidgetCard={assetWidgetCard}
            assetFlag={assetFlag}
          />
          <ClaimsList
            claimsData={claimsData}
            claimsFlag={claimsFlag}
            props={this.props}
          />
          <UpcomingEvents
            upcomingEventsData={upcomingEventsData}
            upcomingEventsFlag={upcomingEventsFlag}
          />
        </>
      );
    }
  }
}
