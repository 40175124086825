import React, { useState } from "react";
import { Grid, Box, TableRow, TableCell } from "@material-ui/core";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { EmptyCollection, TableListComp } from "../../../shared_elements";
import {assetType} from "../../../constants";
import config  from '../../../config';

const ClaimsList = ({ claimsData,claimsFlag, props }) => {
  const options = {
      chart: {
        type: 'pie'
      },
      title: {
        text: 'Claims Status'
      },
      plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            depth: 35,
            showInLegend: true,
            dataLabels: {
                enabled: false,
                format: '{point.name}'
            }
        }
    },
      series:  [{
        name: 'Claims',
        colorByPoint: true,
        data: [{
          name: `Paid (${claimsData.paid_payout ? claimsData.paid_payout : 0})`,
          color: '#81a27b',
          y: claimsData.paid_payout ? claimsData.paid_payout : 0,
        },
        {
          name: `Pending Payout (${claimsData.pending_payout ? claimsData.pending_payout : 0})`,
          color: '#5c95db',
          y: claimsData.pending_payout ? claimsData.pending_payout : 0
        },
        {
          name: `Fund Shortage (${claimsData.funds_shortage ? claimsData.funds_shortage : 0})`,
          color: '#ff6565',
          y: claimsData.funds_shortage ? claimsData.funds_shortage : 0
        },
        {
          name: `Under Review (${claimsData.claim_under_review  ? claimsData.claim_under_review : 0})`,
          color: '#a2a05b',
          y: claimsData.claim_under_review  ? claimsData.claim_under_review : 0
        },
        {
          name: `Pending Approval (${claimsData.claim_pending_approval ? claimsData.claim_pending_approval : 0})`,
          color: '#78bcb8',
          y: claimsData.claim_pending_approval ? claimsData.claim_pending_approval : 0
        },
        ],
      }],
      credits: {
        enabled: false
      }
    }
  return (
    <Box sx={{flexGrow:1}}>
      <Grid container spacing={2}>
        <Grid item md={claimsData && claimsData.claims && claimsData.claims.length ? 8 : 12}>
          <section className="claims-section">
            <h2 className="header">Claims Tracker</h2>
            {
              claimsFlag
              ?
              <TableListComp
                heads={[
                    { label:'MSN', sortOption:false },
                    { label:'Type', sortOption:false },
                    { label:'Claim Amount ', sortOption:false },
                    { label:'Claim Status', sortOption:false },
                    { label:'Payout Approved Amount', sortOption:false },
                    { label:'Payout Status', sortOption:false }
                ]}
                data={claimsData.claims.map((item, index) => (
                  <TableRow hover style={{ cursor: "pointer" }} key={index} onClick={()=> {props.history.push(`${assetType[item.asset.asset_type]}/${item.asset.slug}/maintenance/${item.asset_type === "lg" ? 'landing-gears' : item.asset_type}/view/${item.shop_visit_id}`)}}>
                    <TableCell>
                      <h4>{item.asset.asset_type == 1 ? `MSN ${item.asset.msn}` : `ESN ${item.asset.esn}`}</h4>
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>US$ {item.claim_amount}</TableCell>
                    <TableCell>{item.status.label}</TableCell>
                    <TableCell>US$ {item.amount_paid}</TableCell>
                    <TableCell>{item.payout_status.label}</TableCell>
                  </TableRow>
                ))}
                noRecord={
                  claimsData && claimsData.claims && claimsData.claims.length ? null : (
                    <EmptyCollection title="No Records found" />
                  )
                }
              />
              :
              <div>
                <p className="loader flex-centered">Loading...</p>
              </div>
            }
          </section>
        </Grid>
        {
          claimsData && claimsData.claims && claimsData.claims.length ?
          <Grid item md={4}>
            <div className="claims-side-card" style={{background: '#fff'}}>
              <div className="stats-data">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
                />
              </div>
            </div>
          </Grid>
          :null
        }
      </Grid>
    </Box>
  );
};
export default ClaimsList;
