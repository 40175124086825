import React, { Component } from 'react';
import { BackBanner, LessorForm } from '../components';
import { lessorUpdateApi } from '../apiServices'
export default class LessorSelection extends Component {
  constructor(props){
    super(props);
    this.state = {
      error:{},
      lessor:{},
      formSubmitLoader: false
    }
    this.lessorUpdateApi = lessorUpdateApi.bind(this);
  }
  handleChange = (event, keyParam, value) => {
    this.setState({
      [keyParam]: value
    })
  }
  handleError = (value, key) => {
    this.setState((prevState)=> ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]: value ?'':'Please select Lessor'
      }
    }))
  }
  onSubmit = () => {
    if(this.state.lessor && this.state.lessor.value){
      this.lessorUpdateApi(this.props, { lessor_id: this.state.lessor.value})
    }else{
      this.setState(prevState => ({
        ...prevState,
        error: {
          ...prevState.error,
          lessor: 'Please select Lessor'
        }
      }))
    }
  }
  render(){
    const { error, lessor, formSubmitLoader } = this.state;
    return(
      <BackBanner>
        <div className="auth-form-wrapper lessor-selection-from">
          <h2 className="form-title">You are signed in to SPARTA!</h2>
          <p className="form-para">Please choose an account to proceed</p>
          <LessorForm
            error={error}
            lessor={lessor}
            placeholder="Select Lessor/Inspector "
            handleChange={this.handleChange}
            onSubmit={this.onSubmit}
            formSubmitLoader={formSubmitLoader}
            handleError={this.handleError}
            resetErrorKey={(key) => {
              this.setState(prevState => ({
                ...prevState,
                error: {
                  ...prevState.error,
                  [key]: ''
                }
              }))
            }}
          />
        </div>
      </BackBanner>
    )
  }
}
