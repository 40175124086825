export const shopVisitObj = {
  event: '',
  workscope_details: '',
  shop_visit_facility: '',
  facility_location: '',
  shop_visit_date: null,
  cost: '',
  removal_reason: '',
  tsn: '',
  csn: '',
  prsv:false
}
export const claimObj = {
  submission_date:null,
  claim_amount:'',
  description:'',
  remarks:'',

}
export const errorCode = {
  event:{
    0:'',
    1:"Please select Shop Visit for"
  },
  eventObj:{
    required:true
  },
  shop_visit_date:{
    0:'',
    1:'Please enter Shop Visit Date',
    5:'Please enter a valid date'
  },
  shop_visit_dateObj:{
    required:true,
    datePattern:true
  },
  cost:{
    0:'',
    1:'Please enter Shop Visit Cost'
  },
  costObj:{
    required:true
  },
  tsn:{
    0:'',
    1:"Please enter TSN at Shop Visit"
  },
  tsnObj:{
    required:true
  },
  csn:{
    0:'',
    1:'Please enter CSN at Shop Visit'
  },
  csnObj:{
    required:true
  }
}
export const mntEventsHd = [
  { label: 'Shop Visit for', sortOption: false },
  { label: 'Shop Visit Date ', sortOption: false },
  { label: 'TSN at Shop Visit', sortOption:false},
  { label: 'CSN at Shop Visit', sortOption:false},
  { label: "Reason of Shop Visit", sortOption:false},
  { label: "Shop Visit Workscope", sortOption:false},
  { label: 'Shop Visit Facility', sortOption: false },
  { label: 'Shop Visit Cost', sortOption: false },
]

export const claimsHd = [
  { label: 'Event / Shop Visit', sortOption: false },
  { label: 'Submission date', sortOption: false },
  { label: 'Amount', sortOption: false },
  { label: 'Status', sortOption: false },
]

export const errorCodeClaim = {
  claim_amount:{
    0:'',
    1:'Please enter Claim Amount'
  },
  claim_amountObj : {
    required:true
  },
  submission_date : {
    0:'',
    1:'Please enter Submission Date',
    5:'Please enter a valid date'
  },
  submission_dateObj : {
    required:true,
    datePattern:true
  }
}
export const errorCodeClaimPayout = {
  amount_paid:{
    0:'',
    1:'Please enter Payout Amount'
  },
  amount_paidObj:{
    required:true
  },
  reference_number:{
    0:'',
    1:'Please enter Reference Number'
  },
  reference_numberObj:{
    required:true
  }
}
export const errorCodeReasonForRejection = {
  reason_for_rejection:{
    0:'',
    1:'Please enter the Reason for Rejection'
  },
  reason_for_rejectionObj:{
    required: true
  }
}
