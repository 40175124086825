import { globalGetService, globalPostService, globalPutService } from '../../utils/globalApiServices';
import { checkApiStatus } from '../../utils';
export function getAssemblyUpcomingEventListApi(props={}, assemblyType=""){
  this.setState({ pageLoader:true })
  globalGetService(`maintenance/asset-type/${assemblyType}/asset/${props.match.params.assetSlug}/new-upcoming-events/`)
  .then(response => {
    this.setState({ pageLoader:false })
    if(checkApiStatus(response)){
      this.setState({
        upcomingEvents: response.data.data.upcoming_events
      })
    }
  })
}
export function getAssemblyEventListApi(props={}, assemblyType=""){
  this.setState({skeletonLoader:true});
  globalGetService(`maintenance/asset-type/${assemblyType}/asset/${props.match.params.assetSlug}/shopvisit/`)
  .then(response => {
    this.setState({skeletonLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        eventHistories: response.data.data
      })
    }
  })
}
export function getAssemblyShopVisitApi(props={}, assemblyType=''){
  this.setState({
    pageLoader:true
  })
  globalGetService(`maintenance/asset-type/${assemblyType}/asset/${props.match.params.assetSlug}/shopvisit/${props.match.params.id}/`)
  .then(response => {
    this.setState({
      pageLoader:false
    })
    if(checkApiStatus(response)){
      this.setState({
        event: response.data.data,
      })
    }
  })
}
export function addEditAssemblyShopVisitApi(props={}, data={}, assemblyType=''){
  return new Promise(
    function(resolve, reject){
      let payload = Object.assign({}, data)
      if(data.id){
        delete payload['event'];
        globalPutService(`maintenance/asset-type/${assemblyType}/asset/${props.match.params.assetSlug}/shopvisit/${data.id}/`, payload)
        .then(response => {
          resolve(response)
        })
      }else{
        payload = {
          ...payload,
          event_id: data.event.event_id
        }
        delete payload['event'];
        globalPostService(`maintenance/asset-type/${assemblyType}/asset/${props.match.params.assetSlug}/shopvisit/`, payload)
        .then(response => {
          resolve(response);
        })
      }
    }
  )
}
export function addEditAssemblyClaimApi(props={},data={}){
  return new Promise(
    function(resolve,reject){
      if(data.id){
        globalPutService(`maintenance/${props.match.params.assetType}/asset/${props.match.params.assetSlug}/claims/${data.id}/`, data)
        .then(response => {
          resolve(response)
        })
      }else{
        globalPostService(`maintenance/${props.match.params.assetType}/asset/${props.match.params.assetSlug}/claims/`, data)
        .then(response => {
          resolve(response)
        })
      }
    }
  )
}
export function getAssemblyClaimListApi(props={}, query={}){
  this.setState({pageLoader: true})
  globalGetService(`maintenance/${props.match.params.assetType}/asset/${props.match.params.assetSlug}/claims/`, {shop_visit_id:props.match.params.id})
  .then(response => {
    this.setState({pageLoader: false})
    if(checkApiStatus(response)){
      this.setState({
        claims: response.data.data
      })
    }
  })
}
export function toggleAssemblyClaimStatusApi(props={}, id, data={}){
  this.setState({pageLoader: true})
  globalPostService(`maintenance/claim/${id}/update-status/`, data)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        claims: prevState.claims.map(claim => claim.id === id ? response.data.data:claim),
      }));
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
    this.getAssemblyClaimListApi(this.props)
  })
}
export function getAssemblyEventsApi(props={}, assemblyType=""){
  globalGetService(`maintenance/asset-type/${assemblyType}/asset/${props.match.params.assetSlug}/events/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        mntEvents:response.data.data
      }))
    }
  })
}
export function getClaimListApi(props={}, query={}){
  this.setState({
    skeletonLoader: true
  })
  globalGetService(`maintenance/${props.match.params.assetType}/asset/${props.match.params.assetSlug}/claims/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        claims: response.data.data,
        skeletonLoader:false
      })
    }
  })
}
