import React, { Component } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { Skeleton } from '@material-ui/lab';
import { TableListComp, EmptyCollection, PageLoader, LicenseFailModal } from '../../../../shared_elements';
import { STableLoader} from '../../../../shared_elements/loaders';
import { UpcomingShopVisit, ShopVisitHistory, AddEditShopVisit, NoUpcomingEvents, Claims } from '../../Elements';
import { getAssemblyEventListApi, getAssemblyUpcomingEventListApi, getAssemblyEventsApi, addEditAssemblyShopVisitApi, getClaimListApi } from '../../apiServices';
import { mntEventsHd } from '../../';
import { permissionCheckFn } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
class ApuAssembly extends Component {
  constructor(props){
    super(props);
    this.state = {
      tabIndex: 0,
      eventHistories: [],
      upcomingEvents: [],
      mntEvents: [],
      skeletonLoader:false,
      pageLoader:false,
      licenseModal:false,
      licenseModalMessage:'',
      claims:[]
    }
    this.getAssemblyUpcomingEventListApi = getAssemblyUpcomingEventListApi.bind(this);
    this.getClaimListApi = getClaimListApi.bind(this);
    this.getAssemblyEventListApi = getAssemblyEventListApi.bind(this);
    this.addEditAssemblyShopVisitApi = addEditAssemblyShopVisitApi.bind(this);
    this.getAssemblyEventsApi = getAssemblyEventsApi.bind(this);
  }
  componentDidMount(){
    this.getAssemblyUpcomingEventListApi(this.props, 'apu');
    this.getAssemblyEventListApi(this.props, 'apu');
    this.getAssemblyEventsApi(this.props, 'apu');
    this.getClaimListApi(this.props, {asset_type:'apu'});
  }
  handleChange = (event, newValue) => {
    this.setState(prevState => ({
      ...prevState,
      tabIndex:newValue
    }))
  }
  render(){
    const { tabIndex, eventHistories, upcomingEvents, mntEvents, skeletonLoader, pageLoader, claims, licenseModal, licenseModalMessage } = this.state;
    return(
      <div className="maintenance-module-section">
        <div className="maintenance-heads">
          <h3>APU</h3>
          <p>Section holds Maintenance claims of APU.</p>
        </div>
        <Tabs
          value={tabIndex}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
          aria-label="full width tabs example"
        >
          <Tab label="Event History"
            onClick={() => trackActivity('Tab Clicked',
              {
                page_title: 'Maintenance Claims',
                asset_slug: this.props.match.params.assetSlug,
                asset_type: this.props.match.params.assetType,
                tab_name: 'Event History'
              })}
            />
          <Tab label="Upcoming Events"
            onClick={() => trackActivity('Tab Clicked',
              {
                page_title: 'Maintenance Claims',
                asset_slug: this.props.match.params.assetSlug,
                asset_type: this.props.match.params.assetType,
                tab_name: 'Upcoming Events'
              })}
            />
          <Tab label="Claims"
            onClick={() => trackActivity('Tab Clicked',
              {
                page_title: 'Maintenance Claims',
                asset_slug: this.props.match.params.assetSlug,
                asset_type: this.props.match.params.assetType,
                tab_name: 'Claims'
              })}
            />
        </Tabs>
        <div className="shop-visit-list" text="">
          { tabIndex === 0 ? skeletonLoader ? <STableLoader count={8} />:
            <div style={{position:'relative'}}>
              {skeletonLoader ? <div style={{float:'right'}}><Skeleton animation="wave" width={160} height={48} /></div>:
              permissionCheckFn({secondaryKey:'apu', keyIndex:'C'}) ?
                <AddEditShopVisit
                  assemblyType="apu"
                  buttonText='Shop Visit'
                  mode="Add"
                  heading='Add Maintenance Event'
                  mntEvents={mntEvents}
                  getResponseBack={() => this.getAssemblyEventListApi(this.props, 'apu')}
                  pageType="Add Shop Visit"
                  toggleLicenseFn={(modal, message) => this.setState({licenseModal:modal, licenseModalMessage:message})}
                /> : null
              }
              <TableListComp
                heads={mntEventsHd}
                data={eventHistories.map((item,index) =>
                  <ShopVisitHistory
                    key={index}
                    item={item}
                    viewDetail={() => this.props.history.push(`/${this.props.match.params.assetType}/${this.props.match.params.assetSlug}/maintenance/apu/view/${item.id}`)}
                  />
                )}
                noRecord={eventHistories.length ? null: <EmptyCollection title="No records found" />}
              />
          </div>: null
          }
          { tabIndex === 1 ? pageLoader ? <PageLoader /> :
            <div>
              { upcomingEvents.length ?
                <div>
                  {upcomingEvents.map((item, index) =>
                    <UpcomingShopVisit item={item} key={index} assembly="Shop Visit" />
                  )}
                </div>:
                <div>
                  <NoUpcomingEvents />
                </div>
              }
            </div> : null
          }
          { tabIndex === 2 ? pageLoader ? <PageLoader /> :
            <div>
              <Claims claims={claims} skeletonLoader={skeletonLoader} props={this.props} />
            </div> : null
          }
        </div>
        <LicenseFailModal licenseModalMessage={licenseModalMessage} licenseModal={licenseModal}  toggleModalFn={() =>  this.setState({licenseModal:false, licenseModalMessage:'', })} />
      </div>
    )
  }
}
export default withSnackbar(ApuAssembly);
