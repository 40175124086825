import React, { Component, Fragment } from 'react';
import { Tabs, Tab, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import { TableListComp, EmptyCollection, PageLoader, LicenseFailModal } from '../../../../shared_elements';
import { ShopVisitHistory, AddEditShopVisit, NoUpcomingEvents, Claims } from '../../Elements';
import { EngineUpcomingEvent } from '../components';
import { getRegionListAc, getEngineTypesAc } from '../../../../shared_elements/actionCreators';
import { getAssemblyEventListApi, addEditAssemblyShopVisitApi, getAssemblyUpcomingEventListApi, getAssemblyEventsApi, getClaimListApi } from '../../apiServices';
import { shopVisitObj, mntEventsHd } from '../../';
import { STableLoader } from '../../../../shared_elements/loaders';
import { permissionCheckFn } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
class EngineAssembly extends Component {
  constructor(props){
    super(props);
    this.state = {
      tabIndex: 0,
      tabWorkScopeIndex: 0,
      eventHistories: [],
      upcomingEvents: [],
      shopVisit: shopVisitObj,
      mntEvents: [],
      modal:false,
      skeletonLoader:false,
      pageLoader:false,
      licenseModal:false,
      licenseModalMessage:'',
      claims:[]
    }
    this.getAssemblyUpcomingEventListApi = getAssemblyUpcomingEventListApi.bind(this);
    this.getClaimListApi = getClaimListApi.bind(this);
    this.getAssemblyEventListApi = getAssemblyEventListApi.bind(this);
    this.addEditAssemblyShopVisitApi = addEditAssemblyShopVisitApi.bind(this);
    this.getAssemblyEventsApi = getAssemblyEventsApi.bind(this);
  }
  componentDidMount(){
    this.getAssemblyUpcomingEventListApi(this.props, 'engine');
    this.getAssemblyEventListApi(this.props, 'engine');
    this.getAssemblyEventsApi(this.props,'engine');
    this.getClaimListApi(this.props, {asset_type:'engine'});
  }
  componentWillReceiveProps(nextProps){
    this.getAssemblyUpcomingEventListApi(nextProps, 'engine');
    this.getAssemblyEventListApi(nextProps, 'engine');
    this.getAssemblyEventsApi(nextProps,'engine');
    this.getClaimListApi(nextProps, {asset_type:'engine'});
  }
  handleChange = (event, newValue, tabKey) => {
    this.setState(prevState => ({
      ...prevState,
      [tabKey]:newValue
    }))
  }
  toggleModalFn = (flag) => {
    this.setState({
      modal:flag
    })
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      shopVisit: {
        ...prevState.shopVisit,
        [keyParam]: data
      }
    }))
  }
  render(){
    const { tabIndex, tabWorkScopeIndex, eventHistories, upcomingEvents, mntEvents, skeletonLoader, pageLoader, claims, licenseModal,licenseModalMessage } = this.state;
    console.log("upcomingEvents",upcomingEvents);
    return(
      <div className="maintenance-module-section">
      <div className="maintenance-heads">
        <h3>Engine</h3>
        <p>Section holds Maintenance claims of Engine.</p>
      </div>
        <Tabs
          value={tabIndex}
          onChange={(event, value) => this.handleChange(event, value, 'tabIndex')}
          indicatorColor="primary"
          textColor="primary"
          variant="normal"
          aria-label="full width tabs example"
        >
          <Tab label="Event History"
            onClick={() => trackActivity('Tab Clicked',
              {
                page_title: 'Engine Tabs',
                asset_slug: this.props.match.params.assetSlug,
                asset_type: this.props.match.params.assetType,
                assembly_type: this.props.assemblyType,
                tab_name: 'Event History'
              })}
             />
          <Tab label="Upcoming Events" onClick={() => trackActivity('Tab Clicked',
            {
              page_title: 'Engine Tabs',
              asset_slug: this.props.match.params.assetSlug,
              asset_type: this.props.match.params.assetType,
              assembly_type: this.props.assemblyType,
              tab_name: 'Upcoming Events'
            })}
           />
          <Tab label="Claims" onClick={() => trackActivity('Tab Clicked',
            {
              page_title: 'Engine Tabs',
              asset_slug: this.props.match.params.assetSlug,
              asset_type: this.props.match.params.assetType,
              assembly_type: this.props.assemblyType,
              tab_name: 'Claims'
            })}
           />
        </Tabs>
        <div className="shop-visit-list">
          { tabIndex === 0 ? skeletonLoader ? <STableLoader count={8}  /> :
            <Fragment >
              {isMobile ? null : <p className="assembly-desc">Projected upcoming Shop Visits based on data available for engines</p>}
              { permissionCheckFn( {secondaryKey:'engines', keyIndex:'C'}) ?
                <AddEditShopVisit 
                  assemblyType='engine' 
                  buttonText="Shop Visit" 
                  mode="Add" 
                  mntEvents={mntEvents} 
                  getResponseBack={() => this.getAssemblyEventListApi(this.props, 'engine')} 
                  pageType="Add Shop Visit"
                  toggleLicenseFn={(modal, message) => this.setState({licenseModal:modal, licenseModalMessage:message})}
                /> : null
              }
              <TableListComp
                heads={mntEventsHd}
                data={eventHistories.map((item,index) =>
                  <ShopVisitHistory
                    key={index}
                    item={item}
                    viewDetail={() => this.props.history.push(`/${this.props.match.params.assetType}/${this.props.match.params.assetSlug}/maintenance/engine/view/${item.id}`)}
                  />
                )}
                noRecord={eventHistories.length ? null : <EmptyCollection title="No records found" />}
              />
          </Fragment> : null
          }
          { tabIndex === 1 ? pageLoader ? <PageLoader /> :
            <div className="engine-sv-card">
              { upcomingEvents.length ?
                <div>
                  <Tabs
                    value={tabWorkScopeIndex}
                    onChange={(event, value) => this.handleChange(event, value, 'tabWorkScopeIndex')}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="normal"
                    aria-label="full width tabs example"
                  >
                    {upcomingEvents.map((item, index) =>
                      <Tab label={item.name} />
                    )}
                  </Tabs>
                  {upcomingEvents.map((item, index) =>
                    index === tabWorkScopeIndex ?
                    <div className="tab-content">
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <p className="text-label">Engine Type</p>
                          <p className="para-ui">{item.type.name}</p>
                        </Grid>
                        <Grid item xs={3}>
                          <p className="text-label">Region of Operation</p>
                          <p className="para-ui">{item.region.name}</p>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        {item.workscopes.map((wk) =>
                          <EngineUpcomingEvent workScope={wk} />
                        )}
                      </Grid>
                    </div>:null
                  )}
                </div>:
                <NoUpcomingEvents />
              }
            </div>:null
          }
          { tabIndex === 2 ? pageLoader ? <PageLoader /> :
            <div>
              <Claims claims={claims} skeletonLoader={skeletonLoader} props={this.props} />
            </div> : null
          }
        </div>
        <LicenseFailModal licenseModalMessage={licenseModalMessage} licenseModal={licenseModal}  toggleModalFn={() =>  this.setState({licenseModal:false, licenseModalMessage:'', })} />
      </div>
    )
  }
}
const mapStateToProps = state => ({
  engineTypes: state.shareReducer.engineTypes,
  regions: state.shareReducer.regions
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getRegionList: () => dispatch(getRegionListAc()),
    getEngineTypes: (query) => dispatch(getEngineTypesAc(query)),
  }
};
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(EngineAssembly));
